import React from "react";
import { Helmet } from "react-helmet";
import { defaultDescription, mainKeywords } from "../utils/constants";

const { REACT_APP_WEBSITE_NAME } = process.env;

export default function MyHelmet({
  title = "",
  description = defaultDescription,
  link = "/",
  keywords = "",
  noIndex = false,
}) {
  const metaTitle = title ? `${title} | FeelingHub` : "FeelingHub";
  const metaLink = REACT_APP_WEBSITE_NAME + link;
  const metaKeywords = [...mainKeywords, ...(keywords || [])]?.join(", ");
  const metaRobots = noIndex ? "noindex, nofollow" : "index, follow";
  const appImage = "/ogImage.png";

  return (
    <Helmet>
      <title>{metaTitle}</title>
      <meta name="description" content={description} />
      <link rel="canonical" href={metaLink} />
      <meta name="keywords" content={metaKeywords} />
      <meta name="robots" content={metaRobots} />
      <link rel="icon" href={appImage} />

      {/* OG TAgs */}
      <meta property="og:url" content={metaLink} />
      <meta property="og:title" content={metaTitle} />
      <meta property="og:description" content={description} />
      <meta property="og:type" content="website" />
      <meta property="og:image" content={appImage} />
      <meta property="og:image:width" content="600" />
      <meta property="og:image:height" content="315" />

      {/* Twitter Tags */}
      {/* <meta property="twitter:site" content={""} /> */}
    </Helmet>
  );
}
