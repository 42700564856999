import React, { useEffect, useState } from "react";
import { Button, Col, Row, Form } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { ReactComponent as OpenEyeIcon } from "../../../assets/icons/open-eye.svg";
import { ReactComponent as CloseEyeIcon } from "../../../assets/icons/close-eye.svg";
import { signinRoute } from "../../../routes/routesPath";
import { ErrorField } from "../../../Component/common/errorField";
import { handleApiRequest } from "../../../services/handleApiRequest";
import { resendOtp, resetPassword, sendOtp } from "../../../redux/auth/thunk";
import { Formik, useFormik } from "formik";
import { emailSchema, resetPassowrdSchema } from "../../../utils/validationSchemas";
import { successMsg } from "../../../utils/toastMessage";
import SideSection from "../components/sideSection";
import MyForm from "../../../Component/formComponent";
import AppLogo from "../../../Component/appLogo";

const resetPasswordFields = [
  { value: "otp", label: "OTP", placeholder: "Enter OTP", type: "text" },
  { value: "password", label: "New Password", placeholder: "Enter new password", type: "password" },
  {
    value: "confirmPassword",
    label: "Confirm New Password",
    placeholder: "Re-Enter password",
    type: "password",
  },
];

const defaultValues = {
  otp: "",
  password: "",
  confirmPassword: "",
  email: "",
};

export default function ResetPassowrd() {
  const navigate = useNavigate();
  const [userDetails, setUserDetails] = useState(defaultValues);
  const [resetStep, setResetStep] = useState({ step: 1 });

  const handleSendOtp = async (type) => {
    const { values } = emailForm;
    setResetStep((prev) => ({
      ...prev,
      action: type === "resendOtp" ? "resendingOtp" : "sendingOtp",
    }));
    setUserDetails((prev) => ({ ...prev, email: values.email || prev.email }));

    const response = await handleApiRequest(sendOtp, { email: values.email || userDetails.email });
    if (response.status) {
      successMsg("OTP Sent!!");
      setResetStep((prev) => ({ ...prev, step: 2, action: "" }));
    } else {
      setResetStep((prev) => ({ ...prev, action: "" }));
    }
  };

  const handleResetPassword = async () => {
    setResetStep((prev) => ({ ...prev, action: "updatingPassword" }));

    const response = await handleApiRequest(resetPassword, values);
    setResetStep((prev) => ({ ...prev, action: "" }));

    if (response.status) {
      successMsg("Passowrd Updated!!");
      navigate(signinRoute);
    }
  };

  const emailForm = useFormik({
    initialValues: defaultValues,
    validationSchema: emailSchema,
    onSubmit: () => {
      if (resetStep?.action !== "sendingOtp") {
        handleSendOtp();
      }
    },
  });

  const { values, errors, handleChange, handleSubmit, setFieldValue } = useFormik({
    initialValues: defaultValues,
    validationSchema: resetPassowrdSchema,
    onSubmit: () => {
      if (resetStep?.action !== "updatingPassword") {
        handleResetPassword();
      }
    },
  });

  useEffect(() => {
    if (userDetails.email) {
      setFieldValue("email", userDetails.email);
    }
  }, [userDetails]);

  return (
    <section className="position-relative">
      <div className="">
        <Row className="authContainer m-0">
          <SideSection />
          <Col lg={6} className="authFormContainer p-0">
            <div className="w-100">
              <div className="pointer text-center mb-5" onClick={() => navigate("/")}>
                <AppLogo />
              </div>
              <div className="position-relative mx-3">
                <h4>Reset Password</h4>

                {resetStep.step === 1 ? (
                  <>
                    <p className="mb-4">
                      Enter your email that is registered with us. We will send an OTP to verify
                      your email and reset your password.
                    </p>
                    <Form className="" onSubmit={emailForm.handleSubmit}>
                      <MyForm
                        values={emailForm.values}
                        setFieldValue={emailForm.setFieldValue}
                        errors={emailForm.errors}
                        handleChange={emailForm.handleChange}
                        formFields={[{ value: "email", type: "email" }]}
                      />

                      {resetStep?.action === "sendingOtp" ? (
                        <button className="primaryBtn muted mb-3 rounded w-100 d-flex align-items-center justify-content-center">
                          Sending OTP
                          <i className="growLoader spinner-grow ms-2" role="status"></i>
                        </button>
                      ) : (
                        <button type="submit" className="primaryBtn mb-3  rounded w-100">
                          Send OTP
                        </button>
                      )}
                    </Form>
                  </>
                ) : (
                  <>
                    <p>
                      We have sent a 6 digits OTP on your emial. Use that to verify your email and
                      reset your password.
                    </p>

                    <Form className="" onSubmit={handleSubmit}>
                      <MyForm
                        values={values}
                        setFieldValue={setFieldValue}
                        errors={errors}
                        handleChange={handleChange}
                        formFields={resetPasswordFields}
                      />

                      {resetStep?.action === "updatingPassword" ? (
                        <button className="primaryBtn muted rounded w-100 d-flex align-items-center justify-content-center">
                          Updating Password
                          <i className="growLoader spinner-grow ms-2" role="status"></i>
                        </button>
                      ) : (
                        <button type="submit" className="primaryBtn rounded w-100">
                          Reset Password
                        </button>
                      )}
                    </Form>

                    <div className="text-medium mt-3">
                      <p className="m-0">
                        Didn't receive OTP?
                        {resetStep.action === "resendingOtp" ? (
                          <button className="textBtn text-primary"> Sending OTP...</button>
                        ) : (
                          <button
                            className="textBtn text-primary"
                            onClick={() => handleSendOtp("resendOtp")}
                          >
                            {" "}
                            Resend
                          </button>
                        )}
                      </p>
                    </div>
                  </>
                )}

                <div className="text-medium">
                  <p className="mb-0">
                    Remember Password?
                    <Link to={signinRoute} className="text-decoration-none">
                      {" "}
                      Login
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </section>
  );
}
