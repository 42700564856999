import React, { useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { BiHomeHeart } from "react-icons/bi";
import { PiBookOpenUserBold } from "react-icons/pi";
import { FaRegUserCircle } from "react-icons/fa";
import useAuth from "../../hooks/useAuth";
import * as Path from "../../routes/routesPath";
import { FaRegCommentDots } from "react-icons/fa";
import MyAccount from "../sidebars/myAccount";
import { useSelector } from "react-redux";
import { getUserImage } from "../../utils/helpers";

export default function BottomTab() {
  const { isAuthenticated } = useAuth();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const receiver = searchParams.get("receiver");
  const navigate = useNavigate();

  const { userProfile = {} } = useSelector((state) => state.auth || {});
  const [userAction, setUserAction] = useState({});

  if (pathname === Path.chatRoute && receiver) {
    return;
  }

  return (
    <>
      <div className="mobileBottomTabWrapper d-block d-sm-none">
        <div className="mobileBottomTab position-ralative">
          <button
            className="mobileAddStoryBtn textBtn rounded-circle d-flex justify-content-center align-items-center"
            onClick={() => navigate(isAuthenticated ? Path.addStoryRoute : Path.signinRoute)}
          >
            +
          </button>
        </div>
        <div className="bottomTabActions h-100 d-flex align-items-center justify-content-between">
          <span className="text-white text-center mx-3" onClick={() => navigate("/")}>
            <BiHomeHeart />
            <p className="text-medium text-white m-0">Home</p>
          </span>
          <span className="text-white text-center mx-3" onClick={() => navigate(Path.storiesRoute)}>
            <PiBookOpenUserBold />
            <p className="text-medium text-white m-0">Stories</p>
          </span>
          <span
            className="text-white text-center mx-3"
            onClick={() => navigate(isAuthenticated ? Path.chatRoute : Path.signinRoute)}
          >
            <FaRegCommentDots />
            <p className="text-medium text-white m-0">Chat</p>
          </span>
          <span
            className="text-white text-center mx-3"
            onClick={() => {
              setUserAction({ action: "showMyAccountOptions" });
            }}
          >
            {isAuthenticated ? (
              <img
                src={getUserImage(userProfile)}
                alt={userProfile?.name}
                onError={(e) => {
                  e.target.src = getUserImage();
                }}
                className="img-fluid rounded-circle"
                style={{ width: 25, height: 25 }}
              />
            ) : (
              <FaRegUserCircle />
            )}

            <p className="text-medium text-white m-0">Profile</p>
          </span>
        </div>
      </div>
      <MyAccount userAction={userAction} setUserAction={setUserAction} />
    </>
  );
}
