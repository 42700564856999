import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { handleApiRequest } from "../../services/handleApiRequest";
import { format_date, isArray } from "../../utils/formatersAndParsers";
import { defaultPage, limitOptions } from "../../utils/constants";
import { getSlug, getUserImage } from "../../utils/helpers";
import MyPagination from "../../Component/common/pagination";
import { getOtherUserProfile } from "../../redux/otherUserProfile/thunk";
import MySelect from "../../Component/common/mySelect";
import StoryCard from "../../Component/storyCard/mainStoryCard";
import CardSkeleton from "../../Component/skeleton/cardSkeleton";
import UserDetailsSkeleton from "../../Component/skeleton/userDetailsSkeleton";
import { getStoriesList } from "../../redux/stories/thunk";
import MyHelmet from "../../seo/helmet";
import NoDataFound from "../../Component/common/noDataFound";
import { chatRoute, otherUserProfile } from "../../routes/routesPath";
import { Button } from "react-bootstrap";

export default function OtherUserProfile() {
  const { userId } = useParams();

  const [paginationDetails, setPaginationDetails] = useState(defaultPage);
  const [showReaction, setShowReaction] = useState(false);
  const [userDetails, setUserDetails] = useState({});
  const [stories, setStories] = useState({});

  const handleUserProfile = async () => {
    const response = await handleApiRequest(getOtherUserProfile, { otherUserId: userId });
    if (response.status) {
      setUserDetails(response.data);
    }
  };

  const handleStoriesList = async () => {
    const response = await handleApiRequest(getStoriesList, {
      ...paginationDetails,
      user: userId,
      isPrivate: false,
      anonymousSharing: false,
      listType: "others",
    });
    if (response.status) {
      setStories(response.data);
    }
  };

  const handleLimit = (selected) => {
    setPaginationDetails((prev) => ({ ...prev, limit: selected }));
  };

  useEffect(() => {
    if (userId) {
      handleUserProfile();
    }
  }, [userId]);

  useEffect(() => {
    if (userId) {
      handleStoriesList();
    }
  }, [userId, paginationDetails]);

  return (
    <>
      {userDetails?.name && (
        <MyHelmet
          title={getSlug(userDetails?.name)}
          keywords={[]}
          description={userDetails?.bio?.slice(0, 150) || ""}
          link={`${otherUserProfile}/${getSlug(userDetails.name)}/${userDetails._id}`}
        />
      )}

      <section className="">
        {userDetails.name ? (
          <div className="py-4 px-3 px-lg-5">
            <div className="d-flex align-items-center">
              <img
                src={getUserImage(userDetails)}
                alt={userDetails.name}
                onError={(e) => {
                  e.target.src = getUserImage();
                }}
                className="img-large img-fluid rounded-circle"
              />
              <div className="ms-3">
                <p className="my-0">{userDetails?.name}</p>
                <p className="muted small">User since: {format_date(userDetails?.createdAt)}</p>
                <div className="d-none d-sm-flex align-items-center gap-10">
                  <Link
                    className="secondaryBtn text-white border-secondary rounded text-decoration-none px-2 py-1"
                    to={`${chatRoute}?receiver=${userDetails._id}`}
                  >
                    Message
                  </Link>
                  {/* <Button
                    className="primaryBtn text-white py-1"
                    onClick={() => {
                      setUserAction({ action: "updatePassword" });
                    }}
                  >
                    Report User
                  </Button> */}
                </div>
              </div>
            </div>
            {userDetails?.address && <p className="m-0 my-3">{userDetails?.address}</p>}
            <p className="m-0 my-3">{userDetails?.bio}</p>
          </div>
        ) : (
          <UserDetailsSkeleton />
        )}

        <div className="curvatureContainer border-0">
          <div className="bg-white">
            <div id="curved-corner-topright" className="bg-white" />
          </div>
          <div className="bg-white">
            <div id="curved-corner-topleft" className="bg-white" />
          </div>
        </div>

        <div className="pb-3 px-2 px-lg-5">
          <h4 className="mb-3">Stories</h4>

          <div className={``}>
            {stories?.totalCount > 0 ? (
              isArray(stories.records).map((story, i) => (
                <StoryCard
                  key={i}
                  story={story}
                  showReaction={showReaction}
                  setShowReaction={setShowReaction}
                />
              ))
            ) : stories?.totalCount === 0 ? (
              <NoDataFound />
            ) : (
              Array.from({ length: 3 }).map((_, i) => <CardSkeleton key={i} />)
            )}
            {stories?.totalCount > 0 && (
              <div className="d-flex justify-content-between py-3">
                <div>
                  <MySelect
                    classNamePrefix={"mySelect"}
                    options={limitOptions}
                    isSearchable={false}
                    value={{ value: paginationDetails.limit, label: paginationDetails.limit }}
                    onChange={(selected) => handleLimit(selected)}
                  />
                </div>
                <MyPagination
                  paginationDetails={paginationDetails}
                  setPaginationDetails={setPaginationDetails}
                  totalCount={stories.totalCount}
                  darkPagination={true}
                />
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  );
}
