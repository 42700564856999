import React, { useState } from "react";
import { useSelector } from "react-redux";
import { getMyAccountPagePath, getSlug, getUserImage } from "../../utils/helpers";
import MyStories from "./myStories";
import UpdatePassword from "../../Component/Modals/updatePassword";
import UpdateProfile from "../../Component/Modals/updateProfile";
import Image from "../../Component/common/image";
import MyHelmet from "../../seo/helmet";

const Profile = () => {
  const { userProfile = {} } = useSelector((state) => state.auth || {});
  const [userAction, setUserAction] = useState({});

  return (
    <>
      {userProfile?.name && (
        <MyHelmet
          title={getSlug(userProfile?.name)}
          keywords={[]}
          description={userProfile?.bio?.slice(0, 150) || ""}
          link={getMyAccountPagePath()}
        />
      )}

      <div className="w-100 overflow-auto px-2 px-lg-4 my-5">
        <div className="d-flex d-sm-flex align-items-center">
          <div className="me-3">
            <Image src={getUserImage(userProfile)} alt={userProfile.name} className="img-large" />
          </div>
          <div>
            <b>{userProfile.name}</b>
            <p className="m-0">{userProfile.mobile}</p>
            <p>{userProfile.email}</p>
            <div className="d-none d-sm-flex align-items-center gap-10">
              <button
                className="primaryBtn rounded"
                onClick={() => {
                  setUserAction({ action: "updateProfile" });
                }}
              >
                Edit Profile
              </button>
              <button
                className="secondaryBtn border-secondary text-white rounded"
                onClick={() => {
                  setUserAction({ action: "updatePassword" });
                }}
              >
                Update Password
              </button>
            </div>
          </div>
        </div>
        <div className="d-flex d-sm-none align-items-center gap-10 mt-3">
          <button
            className="primaryBtn rounded"
            onClick={() => {
              setUserAction({ action: "updateProfile" });
            }}
          >
            Edit Profile
          </button>
          <button
            className="secondaryBtn border-secondary text-white rounded"
            onClick={() => {
              setUserAction({ action: "updatePassword" });
            }}
          >
            Update Password
          </button>
        </div>
        <div dangerouslySetInnerHTML={{ __html: userProfile.bio }} className="my-4" />

        <div className="curvatureContainer border-0">
          <div className="bg-white">
            <div id="curved-corner-topright" className="bg-white" />
          </div>
          <div className="bg-white">
            <div id="curved-corner-topleft" className="bg-white" />
          </div>
        </div>

        <MyStories />

        {userAction?.action === "updatePassword" && (
          <UpdatePassword userAction={userAction} setUserAction={setUserAction} />
        )}
        {userAction?.action === "updateProfile" && (
          <UpdateProfile userAction={userAction} setUserAction={setUserAction} />
        )}
      </div>
    </>
  );
};

export default Profile;
