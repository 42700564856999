import React, { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { Col, Nav, Row, Tab } from "react-bootstrap";
import { useSelector } from "react-redux";
import Skeleton from "react-loading-skeleton";
import Marquee from "react-fast-marquee";
import banner from "../../assets/images/homeBanner.webp";
import ourMission from "../../assets/images/ourMission.webp";
import ourStory from "../../assets/images/ourStory.webp";
import HelpPeopleLikeYou from "../../assets/images/helpPeople.webp";
import StoryCard from "../../Component/storyCard/homeStoryCard";
import { handleApiRequest } from "../../services/handleApiRequest";
import { getMainStoriesList } from "../../redux/stories/thunk";
import HeartLine from "../../Component/common/heartLine";
import useAuth from "../../hooks/useAuth";
import { addStoryRoute, signinRoute, storyDetailsRoute } from "../../routes/routesPath";
import ShareBtn from "../../Component/common/shareBtn";
import { SubFooter } from "../../Component/Footer/Footer";
import { ContactUsForm, Faqs } from "../contactUs";
import YourStoryIsWaitingToHappen from "../../Component/common/yourStoryIsWaitingToHappen";
import { isArray } from "../../utils/formatersAndParsers";
import HomeStoryCardSkeleton from "../../Component/skeleton/homeStoryCard";
import { skeletonBaseColor, skeletonHighlightColor } from "../../utils/constants";
import MyHelmet from "../../seo/helmet";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { getSlug } from "../../utils/helpers";

const settings = {
  infinite: true,
  autoplay: true,
  autoplaySpeed: 5000,
  speed: 2000,
  dots: true,
  draggable: true,
  pauseOnHover: true,
  arrows: false,
  slidesPerRow: 3,
  responsive: [
    { breakpoint: 400, settings: { slidesPerRow: 1 } },
    { breakpoint: 576, settings: { slidesPerRow: 2, className: "homeStorySlider text-medium" } },
    { breakpoint: 950, settings: { slidesPerRow: 2 } },
    // { breakpoint: 1200, settings: { slidesPerRow: 3 } },
  ],
  // afterChange: (currentSlide) => {
  //   console.log("currentSlide", currentSlide);
  //   const activeDot = document.getElementsByClassName("slick-active")?.[0];
  //   console.log("activeDot", activeDot);
  //   if (activeDot) {
  //     activeDot.scrollIntoView({ behavior: "smooth", inline: "nearest" });
  //   }
  // },
};

export default function Home() {
  const { isAuthenticated } = useAuth();
  const { width } = useWindowDimensions();
  const [searchParams, setSearchParams] = useSearchParams();
  const { categories = [] } = useSelector((state) => state.common || {});

  const [storiesList, setStoriesList] = useState({});
  const [category, setCategory] = useState();

  const handleCategory = (key) => {
    setCategory(key);
    setSearchParams(() => `category=${key}`);
  };

  const handleStoriesList = async (category) => {
    const request = { limit: 30, category };
    const response = await handleApiRequest(getMainStoriesList, request);

    if (response.status) {
      setStoriesList((prev) => ({ ...prev, [category]: response.data?.records || [] }));
    }
  };

  useEffect(() => {
    if (categories?.length > 0) {
      let primaryCategory = "";

      categories
        .filter((cat) => cat.isPrimary)
        .map((cat) => {
          handleStoriesList(cat._id);
          if (!primaryCategory) {
            primaryCategory = cat._id;
          }
        });

      setCategory(primaryCategory);
    }
  }, [categories]);

  return (
    <>
      <MyHelmet
        title={"Share feelings online without any fear"}
        keywords={[]}
        description="Feelinghub is a anonymous feelings sharing platform. Share feelings online, post life stories and connect with community through encrypted chats."
        link=""
      />

      <section className="home">
        <Row className="homeBanner d-flex m-0 px-3 px-lg-5">
          <Col lg={8} md={7} className="d-flex align-items-center justify-content-center">
            <div className="px-sm-5 mt-5 mt-lg-0">
              <h1 className="fw-normal h3 mb-3">
                Are you <span className="fw-sbold text-secondary">Stressed? </span>
                <br />
                or Looking for Someone to share your{" "}
                <span className="fw-sbold text-secondary">Feelings?</span>
                <br />
                <Link
                  className="pointer fw-bold text-primary text-decoration-underline"
                  to={isAuthenticated ? addStoryRoute : signinRoute}
                >
                  Join Us now
                </Link>
              </h1>
              <p className="bannerDesc">
                Feelinghub is a unique platform where you can share your feelings, emotions, and
                problems with others. You can also share anonymously or save as private for you.
                Receive support from our community or our admin team. Explore our programs and sign
                up now!
              </p>
            </div>
          </Col>
          <Col lg={4} md={5} className="h-100">
            <img src={banner} alt="Stressed lady" className="img-fluid h-100" />
          </Col>
        </Row>

        <h2 className="text-center mt-5 mb-0 text-color">Users Stories</h2>
        <HeartLine className="mb-5" />

        <div>
          <Tab.Container id="left-tabs-example" activeKey={category}>
            <ul variant="pills" className="list-unstyled text-nowrap overflow-auto text-center">
              {isArray(categories)?.length > 0
                ? isArray(categories)
                    .filter((cat) => cat.isPrimary)
                    .map((tab) => (
                      <li
                        key={tab._id}
                        className={`homeCategoryTab mx-md-2 d-inline-block`}
                        onClick={() => handleCategory(tab._id)}
                      >
                        <Nav.Link
                          eventKey={tab._id}
                          className={`${
                            category === tab._id ? "activeTab" : ""
                          } text-color px-3 py-2 rounded`}
                        >
                          <h6 className="m-0">{tab.specialName || tab.name}</h6>
                        </Nav.Link>
                      </li>
                    ))
                : Array.from({ length: 4 }).map((_, i) => (
                    <li key={i} className={`homeCategoryTab mx-2 d-inline-block`}>
                      <Skeleton
                        width={130}
                        height={40}
                        baseColor={skeletonBaseColor}
                        highlightColor={skeletonHighlightColor}
                      />
                    </li>
                  ))}
            </ul>
            <Tab.Content>
              {isArray(categories).length > 0 ? (
                categories
                  .filter((cat) => cat.isPrimary)
                  .map((tab) => (
                    <Tab.Pane key={tab._id} eventKey={tab._id}>
                      <div className="mx-2 mx-lg-5 my-5">
                        {isArray(storiesList[tab._id]).length > 0 ? (
                          <>
                            <Marquee pauseOnHover={true}>
                              <div className="d-flex align-items-center">
                                {isArray(storiesList[tab._id])
                                  .slice(0, Math.ceil(storiesList[tab._id].length / 2))
                                  .map((story) => (
                                    <Link
                                      key={story._id}
                                      to={`${storyDetailsRoute}/${getSlug(story.title)}/${
                                        story._id
                                      }`}
                                      className="my-2 text-decoration-none text-dark"
                                    >
                                      <StoryCard story={story} />
                                    </Link>
                                  ))}
                              </div>
                            </Marquee>
                            <Marquee pauseOnHover={true} direction="right">
                              <div className="d-flex align-items-center">
                                {isArray(storiesList[tab._id])
                                  .slice(
                                    Math.ceil(storiesList[tab._id].length / 2),
                                    storiesList[tab._id].length - 1
                                  )
                                  .map((story) => (
                                    <Link
                                      key={story._id}
                                      to={`${storyDetailsRoute}/${getSlug(story.title)}/${
                                        story._id
                                      }`}
                                      className="my-2 text-decoration-none text-dark"
                                    >
                                      <StoryCard story={story} />
                                    </Link>
                                  ))}
                              </div>
                            </Marquee>
                          </>
                        ) : (
                          <div className="d-flex align-items-center overflow-auto">
                            {Array.from({ length: 4 }).map((_, i) => (
                              <div key={i} className="homeStoryCardWidth my-2">
                                <HomeStoryCardSkeleton />
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    </Tab.Pane>
                  ))
              ) : (
                <div className="d-flex align-items-center overflow-auto mx-2 mx-lg-5 my-5">
                  {Array.from({ length: 4 }).map((_, i) => (
                    <div key={i} className="homeStoryCardWidth my-2">
                      <HomeStoryCardSkeleton />
                    </div>
                  ))}
                </div>
              )}
            </Tab.Content>
          </Tab.Container>
        </div>

        <div className="homeGetStarted d-flex py-3 py-sm-5 px-3">
          <YourStoryIsWaitingToHappen />
        </div>

        <h2 className="text-center mt-5 mb-0 text-color">
          Our <span className="text-primary">Philosphy</span>
        </h2>
        <HeartLine className="mb-5" />

        <Row className="align-items-center m-0 px-lg-5 px-2 py-2">
          <Col md={4} className="d-none d-md-flex align-items-center justify-content-center">
            <img
              src={ourMission}
              alt="Our Mission"
              className="img-fluid rounded-circle"
              style={{ maxHeight: 300 }}
            />
          </Col>
          <Col md={8} className="h-100">
            <div className="px-sm-5">
              <h3 className="fw-normal mb-3 text-center text-md-start">Our Mission</h3>
              <p className="bannerDesc my-3 my-md-0">
                Welcome to Feelinghub, where we believe in the power of sharing emotions and
                experiences. Founded in 2023, our innovative Community Service Program aims to
                inspire individuals to open up, connect, and grow. Join us on this journey of
                self-discovery and meaningful connections.
              </p>
            </div>
          </Col>
          <Col md={4} className="d-md-none d-flex align-items-center justify-content-center">
            <img
              src={ourMission}
              alt="Our Mission"
              className="img-fluid rounded-circle"
              style={{ maxHeight: 300 }}
            />
          </Col>
        </Row>

        <HeartLine />

        <Row className="align-items-center m-0 px-lg-5 px-2 py-2">
          <Col md={8} className="h-100">
            <div className="px-sm-5">
              <h3 className="fw-normal mb-3 text-center text-md-start">Our Story</h3>
              <p className="bannerDesc">
                Established in 2023 to meet the community's growing needs, Feelinghub is a
                distinctive Community Service Program providing a fulfilling and interactive
                experience for all participants. We embrace our responsibilities by offering
                inclusive programs accessible to individuals from diverse backgrounds, constantly
                working towards our mission.
              </p>
            </div>
          </Col>
          <Col md={4} className="d-flex align-items-center justify-content-center">
            <img
              src={ourStory}
              alt="Our Story"
              className="img-fluid rounded-circle"
              style={{ maxHeight: 300 }}
            />
          </Col>
        </Row>

        <HeartLine />

        <Row className="align-items-center m-0 px-lg-5 px-2 py-4">
          <Col md={4} className="d-none d-md-flex align-items-center justify-content-center">
            <img
              src={HelpPeopleLikeYou}
              alt="People like you"
              className="img-fluid rounded-circle"
              style={{ maxHeight: 300 }}
            />
          </Col>
          <Col md={8} className="h-100">
            <div className="px-sm-5">
              <h3 className="fw-normal text-center text-md-start">Help People like you</h3>
              <div className="content">
                <div className="bannerDesc my-4">
                  In an age where technology dominates our daily lives. We are dedicated to provide
                  support and resources for those struggling with mental health issues, relationship
                  problems, or other challenges by bringing people together. By sharing these
                  platforms with more individuals, we can expand the reach of these communities and
                  ensure that no one feels isolated or alone in their struggles.
                  <br />
                  <ul>
                    <li>Share the community with the people in need.</li>
                    <li>Donate us to reach more people like you.</li>
                  </ul>
                </div>
              </div>
              <div className="d-flex align-items-center gap-10 mb-4">
                <ShareBtn />
              </div>
            </div>
          </Col>
          <Col md={4} className="d-md-none d-flex align-items-center justify-content-center">
            <img
              src={HelpPeopleLikeYou}
              alt="People like you"
              className="img-fluid rounded-circle"
              style={{ maxHeight: 300 }}
            />
          </Col>
        </Row>

        <HeartLine />

        <ContactUsForm />

        <HeartLine />

        {/* Faqs section */}
        <Faqs className="pt-2" />

        <SubFooter />

        {/* <div className="curvatureContainer">
          <div>
            <div id="curved-corner-topright" />
          </div>
          <div>
            <div id="curved-corner-topleft" />
          </div>
        </div> */}

        {/* <div className="curvatureContainer lowerCurvatureContainer">
          <div>
            <div id="curved-corner-bottomright" className="curvedCorner" />
          </div>
          <div>
            <div id="curved-corner-bottomleft" className="curvedCorner" />
          </div>
        </div> */}
      </section>
    </>
  );
}
