import React from "react";
import noDataImage from "../../assets/images/no-data-placeholder.png";

export default function NoDataFound() {
  return (
    <img
      src={noDataImage}
      alt="No data found"
      className="img-fluid d-flex mx-auto"
      style={{ maxWidth: 400 }}
    />
  );
}
