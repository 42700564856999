import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import successGif from "../../assets/images/successGif.gif";
import useAuth from "../../hooks/useAuth";
import { handleApiRequest } from "../../services/handleApiRequest";
import { useSelector } from "react-redux";
import { isArray } from "../../utils/formatersAndParsers";
import { addStory, getStoryDetails, updateStory } from "../../redux/stories/thunk";
import { useFormik } from "formik";
import { postStorySchema } from "../../utils/validationSchemas";
import { ErrorField } from "../../Component/common/errorField";
import { successMsg } from "../../utils/toastMessage";
import { Link, useNavigate, useParams } from "react-router-dom";
import { handleLoginPop } from "../../utils/helpers";
import CkEditor from "../../Component/formComponent/components/ckEditor";
import { addStoryRoute, contactUsRoute, homeRoute } from "../../routes/routesPath";
import MyHelmet from "../../seo/helmet";

const defaultValues = {
  title: "",
  description: "",
  category: "",
  anonymousSharing: false,
  isPrivate: false,
};

export default function AddStory() {
  const { isAuthenticated } = useAuth();
  const { storyId } = useParams();
  const navigate = useNavigate();

  const { categories = [] } = useSelector((state) => state.common || {});
  const [storyUploaded, setStoryUploaded] = useState(false);

  const handleStoryDetails = async () => {
    const response = await handleApiRequest(getStoryDetails, { storyId });
    if (response.status) {
      const { title, description, category, anonymousSharing, isPrivate, _id } = response.data;
      const oldStoryDetails = {
        title,
        description,
        anonymousSharing,
        isPrivate,
        category: category._id,
        storyId: _id,
      };
      setValues(oldStoryDetails);
    }
  };

  const handlePostStory = async (values, { resetForm, setSubmitting }) => {
    if (!isAuthenticated) return handleLoginPop(true);
    let response = {};
    if (storyId) {
      response = await handleApiRequest(updateStory, values);
    } else {
      response = await handleApiRequest(addStory, values);
    }
    if (response.status) {
      successMsg("Your story is saved");
      setStoryUploaded(true);
      resetForm();
    }
  };

  const { values, errors, isSubmitting, handleChange, handleSubmit, setFieldValue, setValues } =
    useFormik({
      validateOnChange: false,
      validateOnBlur: false,
      initialValues: defaultValues,
      validationSchema: postStorySchema,
      onSubmit: handlePostStory,
    });

  useEffect(() => {
    if (storyId) {
      handleStoryDetails();
    }
  }, [storyId]);

  useEffect(() => {
    if (storyUploaded) {
      setTimeout(() => {
        navigate(homeRoute);
      }, 2000);
    }
  }, [storyUploaded]);

  return (
    <>
      <MyHelmet
        title={"How's your day, Let your inner voice come out"}
        keywords={[
          "Online diary",
          "Share love, anger, confessions",
          "Share feelings anonymously without judgment",
          "How You're Feeling today",
        ]}
        description="Feelinghub allows you to share feelings online or post life stories anonymously where no can judge you. It is a safe space to express yourself."
        link={addStoryRoute}
      />

      {!storyUploaded ? (
        <>
          <section>
            <div className="py-4 px-3">
              <h1 className="h4 my-3 text-center">How You're Feeling today</h1>
              <Form
                className="addStoryWrapper shadow mx-auto p-3 p-lg-4 px-lg-5"
                onSubmit={handleSubmit}
              >
                <div className="my-3">
                  <label className="text-large fw-sbold">Title</label>
                  <input
                    className="myInput form-control"
                    placeholder="Please enter a short title"
                    min={5}
                    max={150}
                    name="title"
                    value={values.title || ""}
                    onChange={handleChange}
                  />
                  <ErrorField error={errors.title} />
                </div>

                <div className="my-3">
                  <label className="text-large fw-sbold">Describe your Feeling</label>
                  <CkEditor
                    data={values.description || ""}
                    onChange={(data) => {
                      setFieldValue("description", data);
                    }}
                  />
                  <ErrorField error={errors.description} />
                </div>
                <div className="my-3">
                  <label className="text-large fw-sbold">Select your Feeling</label>
                  <div className="feelingContainer d-flex align-items-center overflow-auto">
                    {isArray(categories).map((feeling) => (
                      <div
                        key={feeling._id}
                        className="pointer"
                        onClick={() => setFieldValue("category", feeling._id)}
                      >
                        <i
                          className={`iconFilled ${
                            feeling._id === values.category ? "d-inline-block" : "d-none"
                          }`}
                          dangerouslySetInnerHTML={{ __html: feeling?.iconFilled }}
                        />
                        {feeling._id !== values.category && (
                          <i
                            className="iconRegular d-inline-block"
                            dangerouslySetInnerHTML={{ __html: feeling?.iconRegular }}
                          />
                        )}
                        <p className="text-medium mb-0 fw-bold">{feeling.name}</p>
                      </div>
                    ))}
                  </div>
                  <ErrorField error={errors.category} />
                </div>

                <div className="my-2 d-flex align-items-center">
                  <input
                    id="anonymousSharing"
                    className="checkbox pointer me-2"
                    type="checkbox"
                    name="anonymousSharing"
                    checked={values.anonymousSharing}
                    onChange={handleChange}
                  />
                  <label htmlFor="anonymousSharing" className="pointer">
                    Hide your real name
                  </label>
                </div>
                <div className="my-2 d-flex align-items-center">
                  <input
                    id="isPrivate"
                    className="checkbox pointer me-2"
                    type="checkbox"
                    name="isPrivate"
                    checked={values.isPrivate}
                    onChange={handleChange}
                  />
                  <label htmlFor="isPrivate" className="pointer">
                    Save to your private folder
                  </label>
                </div>
                <div className="d-flex justify-content-center">
                  {isSubmitting ? (
                    <>
                      <button type="submit" className="primaryBtn rounded mt-2 px-5 py-1" disabled>
                        Submitting
                        <i className="growLoader spinner-grow ms-2" role="status" />
                      </button>
                    </>
                  ) : (
                    <button type="submit" className="primaryBtn rounded mt-2 px-5 py-1">
                      Share your Feeling
                    </button>
                  )}
                </div>
              </Form>
            </div>
          </section>
        </>
      ) : (
        <>
          <section className="">
            <div className="d-flex flex-column align-items-center justify-content-center m-lg-5 m-3 shadow p-3">
              <h2 className="mt-4">Your story is now live.</h2>
              <h5 className="mb-3 fw-bold text-center">Have a Nice Cheerful Day!!</h5>
              <img src={successGif} className="img-fluid" />
              <h5 className="text-center">We are always here to support you.</h5>
              <p className="text-center">Let us know what we can do for you...</p>
              <div className="d-flex align-items-center justify-content-center gap-10">
                <Link
                  to={contactUsRoute}
                  className="primaryBtn text-decoration-none rounded px-4 py-2 mb-3"
                >
                  Contact us
                </Link>
                <Link
                  to={homeRoute}
                  className="secondaryBtn text-decoration-none text-white border-secondary rounded px-4 py-2 mb-3"
                >
                  Home
                </Link>
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
}
