import React, { useEffect } from "react";
import { Accordion, Col, Form, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useFormik } from "formik";
import PhoneInput from "react-phone-input-2";
import { conatctUsSchema } from "../../utils/validationSchemas";
import { successMsg } from "../../utils/toastMessage";
import { contactUsReason } from "../../utils/constants";
import { addQuery } from "../../redux/queries/thunk";
import { handleApiRequest } from "../../services/handleApiRequest";
import { ErrorField } from "../../Component/common/errorField";
import MySelect from "../../Component/common/mySelect";
import AskToShare from "../../Component/askToShare";
import { SubFooter } from "../../Component/Footer/Footer";
import HeartLine from "../../Component/common/heartLine";
import {
  FacebookHandle,
  InstagramHandle,
  WhatsappHandle,
  YoutubeHandle,
} from "../../Component/common/socialHandles";
import MyHelmet from "../../seo/helmet";
import { contactUsRoute } from "../../routes/routesPath";

const defaultValues = {
  name: "",
  mobile: "",
  email: "",
  reason: "",
  comment: "",
};

const ContactUs = () => {
  const { pathname } = useLocation();

  return (
    <>
      {pathname.includes(contactUsRoute) && (
        <MyHelmet
          title={"Contact us to share your feedback"}
          keywords={[]}
          description="FeelingHub is always there to help and listen to you. Contact us to share your feedback, suggestion or complaints."
          link="/contact-us"
        />
      )}

      <section className="contactusWrapper position-relative">
        <ContactUsForm />
        <HeartLine />
        <Faqs />
        <HeartLine />
        <AskToShare />
        <SubFooter />
      </section>
    </>
  );
};

export default ContactUs;

export const ContactUsForm = () => {
  const { pathname } = useLocation();
  const { userProfile } = useSelector((state) => state.auth);

  const handleSubmitQuery = async () => {
    const response = await handleApiRequest(addQuery, values);
    resetForm();

    if (response.status) {
      successMsg("Thanks for your response");
    }
  };

  let {
    values,
    errors,
    handleChange,
    setFieldValue,
    setValues,
    handleSubmit,
    isSubmitting,
    resetForm,
    touched,
  } = useFormik({
    initialValues: defaultValues,
    validationSchema: conatctUsSchema,
    onSubmit: handleSubmitQuery,
  });

  useEffect(() => {
    if (userProfile._id) {
      const { name, mobile, email } = userProfile;
      setValues({ ...defaultValues, name, mobile, email });
    }
  }, [userProfile]);

  return (
    <>
      <Row className="align-items-center m-0 px-lg-5 px-2 my-5">
        <Col md={5}>
          <button className="outlineBtn rounded-pill mb-3">Don't be shy</button>
          {pathname.includes(contactUsRoute) ? (
            <h1 className="fw-normal">
              Get in <span className="text-primary">Touch</span>
            </h1>
          ) : (
            <h2 className="fw-normal">
              Get in <span className="text-primary">Touch</span>
            </h2>
          )}
          <p>
            If you are interested in hearing more or express yourself in different ways, thinking of
            working with us, or just think it would be good to have a chat? Just tell us a bit about
            yourself and we’ll be in touch.
          </p>
          <div className="d-none d-md-block">
            <div className="mt-2 d-flex align-items-center">
              <FacebookHandle />
              <InstagramHandle />
              <YoutubeHandle />
              <WhatsappHandle />
            </div>
          </div>
        </Col>
        <Col md={1} className="d-none d-md-block" />
        <Col md={6} lg={5}>
          <h2 className="fw-normal">
            Let's <span className="text-primary">Talk</span>
          </h2>
          <p className="">Let’s bring your idea to life together.</p>
          <Form onSubmit={handleSubmit} style={{ maxWidth: 500 }}>
            <div className="mt-3">
              <input
                type="text"
                className="form-control"
                placeholder="Enter Full Name*"
                name="name"
                value={values.name}
                onChange={handleChange}
              />
              <ErrorField touched={touched} error={errors.name} />
            </div>

            <Row>
              <Col sm={6} className="mt-3 pe-sm-0">
                <div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Email Address*"
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                  />
                  <ErrorField touched={touched} error={errors.email} />
                </div>
              </Col>
              <Col sm={6} className="mt-3">
                <div>
                  <PhoneInput
                    inputClass="w-100 border"
                    buttonClass="border"
                    inputStyle={{ height: 38 }}
                    country="in"
                    placeholder={"Phone Number*"}
                    value={values.mobile}
                    onChange={(value, data, e, formattedValue) => {
                      setFieldValue("mobile", formattedValue);
                    }}
                  />
                  <ErrorField touched={touched} error={errors.mobile} />
                </div>
              </Col>
            </Row>

            <div className="mt-3">
              <MySelect
                placeholder="Select reason*"
                options={contactUsReason}
                value={values.reason}
                onChange={(selected) => setFieldValue("reason", selected)}
              />

              <ErrorField touched={touched} error={errors.reason} />
            </div>

            <div className="my-3 my-sm-3">
              <textarea
                className="form-control"
                placeholder="Your message*"
                name="comment"
                value={values.comment}
                onChange={handleChange}
              />

              <ErrorField touched={touched} error={errors.comment} />
            </div>

            {isSubmitting ? (
              <button className="secondaryBtn muted rounded-pill px-3 py-1 text-white" disabled>
                Submitting
                <i className="growLoader spinner-grow ms-2" role="status" />
              </button>
            ) : (
              <button
                type="submit"
                className="primaryBtn rounded-pill px-3 py-1"
                disabled={isSubmitting}
              >
                Send
              </button>
            )}
          </Form>
        </Col>
      </Row>
    </>
  );
};

export const Faqs = ({ className = "" }) => {
  const { faqsList = [] } = useSelector((state) => state.common || {});

  return (
    <div className={`mx-2 mx-lg-5 px-lg-5 my-5 ${className}`}>
      <h2 className="w-fit border-danger border-bottom ">
        Frequently Asked Questions (FAQS)
        <p className="w-50 border-danger border-bottom mb-1" />
      </h2>
      <Accordion defaultActiveKey={faqsList?.[0]?._id} className="faqsAccordion">
        {faqsList.map((faq) => (
          <Accordion.Item
            key={faq._id}
            eventKey={faq._id}
            className="my-3 border shadow-sm rounded-0 rounded-top"
          >
            <Accordion.Header>{faq.question}</Accordion.Header>
            <Accordion.Body>
              <div dangerouslySetInnerHTML={{ __html: faq.answer }} />
            </Accordion.Body>
          </Accordion.Item>
        ))}
      </Accordion>
    </div>
  );
};
