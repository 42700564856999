import React from "react";
import { Link } from "react-router-dom";
import useWindowDimensions from "../hooks/useWindowDimensions";
import { homeRoute } from "../routes/routesPath";
import { handleLoginPop } from "../utils/helpers";

export default function AppLogo() {
  const { width } = useWindowDimensions();

  return (
    <Link to={homeRoute} className="text-decoration-none pointer text-center">
      <div
        className={`${
          width >= 400 ? "d-flex" : "d-block"
        } align-items-center justify-content-center mb-5`}
        onClick={() => {
          handleLoginPop(false);
        }}
      >
        <img src="/appLogo.png" className="img-fluid h-100 me-2" style={{ maxHeight: 70 }} />
        <h1 className="text-extraLarge text-color mb-0 ms-2">FeelingHub</h1>
      </div>
    </Link>
  );
}
