import React from "react";
import { Outlet, useSearchParams } from "react-router-dom";
import Header from "../Component/Header/Header";
import Footer from "../Component/Footer/Footer";
import BottomTab from "../Component/mobileBottomTab/bottomTab";
import useWindowDimensions from "../hooks/useWindowDimensions";

export default function MainTheme() {
  const { width } = useWindowDimensions();
  const [searchParams] = useSearchParams();
  const receiver = searchParams.get("receiver");

  return (
    <div className="mainWrapper position-relative shadow-sm">
      {receiver && width < 576 ? "" : <Header />}
      <main className="mainBody">
        <Outlet />
      </main>
      <Footer />
      <BottomTab />
    </div>
  );
}
