import { errorMsg } from "../utils/toastMessage";
import { handleApiRequest } from "./handleApiRequest";
import { uploadFile } from "../redux/common/thunk";

const convertToBase64 = async (file, file_type) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);

  return new Promise((resolve, reject) => {
    reader.onload = () => {
      if (file_type.includes("text")) {
        const base64String = reader.result.split(";base64,")[1];
        const base64Uri = `data:text/plain;base64,${base64String}`;
        resolve(base64Uri);
      } else {
        resolve(reader.result);
      }
    };
    reader.onerror = (error) => {
      // console.log("called: error", error);
      reject(error);
    };
  });
};

export const handleFile = async (file, skipFileError = true) => {
  try {
    if (!file || !file.type) return skipFileError ? errorMsg("File not found") : "";
    if (file.size > 2048000) return errorMsg("Max file size can be 2 Mb");

    // const file_type = file.type.split("/").pop();
    // if (!["png", "jpg", "jpeg"].includes(file_type)) {
    //   return errorMsg("Use png, jpg or jpeg file");
    // }
    if (!file.type.includes("image")) {
      return errorMsg("Please upload image only");
    }

    const formData = new FormData();
    formData.append("images", file);

    const file_url = await handleApiRequest(uploadFile, formData);
    return file_url?.data;
  } catch (error) {
    // console.log("file upload error", error);
    errorMsg(error?.message || error);
  }
};
