import React from "react";
import { getUserImage } from "../../utils/helpers";

export default function StoryCard({ story = {} }) {
  return (
    <div className="homeStoryCard homeStoryCardWidth pointer rounded mx-2 px-2 py-4 p-lg-4 text-start">
      <div className="d-flex justify-content-center">
        <img
          src={story.anonymousSharing ? getUserImage() : getUserImage(story.user)}
          alt={story.anonymousSharing ? "userImage" : story.user?.name}
          onError={(e) => {
            e.target.src = getUserImage();
          }}
          className="img-small img-fluid object-fit-cover rounded-circle"
        />
      </div>
      <h5 className="my-3 text-truncate text-center">{story.title || ""} </h5>
      <div
        className="storyDesc truncate-4 m-0"
        dangerouslySetInnerHTML={{ __html: story.description }}
      />
    </div>
  );
}
