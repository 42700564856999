import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const uploadFile = createAsyncThunk("common/uploadFile", async (details, Thunk) => {
  try {
    let response = await axios.post(`utility/uploadFiles`, details);
    return response?.data;
  } catch (error) {
    return Thunk.rejectWithValue(error);
  }
});

export const getCategories = createAsyncThunk("common/getCategories", async (details, Thunk) => {
  try {
    let response = await axios.get(`utility/categories/list`);
    return response?.data;
  } catch (error) {
    return Thunk.rejectWithValue(error);
  }
});

export const manageFirebaseToken = createAsyncThunk(
  "common/manageFirebaseToken",
  async (data, Thunk) => {
    try {
      let response = await axios.post(`utility/firebaseToken/update`, data);
      return response?.data;
    } catch (error) {
      return Thunk.rejectWithValue(error);
    }
  }
);

export const getContentPages = createAsyncThunk("common/getContentPages", async (data, Thunk) => {
  try {
    let response = await axios.get(`utility/cms/page/list`, data);
    let pages = response?.data?.data || [];
    pages = pages?.filter((page) => page.status === "active");
    return { ...response.data, data: pages };
  } catch (error) {
    return Thunk.rejectWithValue(error);
  }
});

export const getContentPage = createAsyncThunk("common/getContentPage", async (data, Thunk) => {
  try {
    let response = await axios.post(`utility/cms/page/content`, data);
    return response?.data;
  } catch (error) {
    return Thunk.rejectWithValue(error);
  }
});

export const getUsersList = createAsyncThunk("common/getUsersList", async (data, Thunk) => {
  try {
    let response = await axios.post(`admin/users/list`, data);
    return response?.data;
  } catch (error) {
    return Thunk.rejectWithValue(error);
  }
});

export const getFaqsList = createAsyncThunk("common/getFaqsList", async (request, Thunk) => {
  try {
    let response = await axios.get(`faqs/list`);
    const data = {
      ...response.data,
      data: response.data?.data?.filter((faq) => faq.status === "active"),
    };
    return data;
  } catch (error) {
    return Thunk.rejectWithValue(error);
  }
});
