import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { htmlToText } from "html-to-text";
import { BiLike } from "react-icons/bi";
import { RiReplyFill } from "react-icons/ri";
import { HiDotsVertical } from "react-icons/hi";
import {
  FaBookmark,
  FaCircleUser,
  FaCommentDots,
  FaFlag,
  FaRegBookmark,
  FaRegCommentDots,
} from "react-icons/fa6";
import beTheFirstToAddComment from "../../assets/images/beTheFirstToAddComment.webp";
import { handleApiRequest } from "../../services/handleApiRequest";
import {
  addComment,
  addCommentReply,
  getCommentList,
  getRepliesList,
  getStoryDetails,
  manageCommentReaction,
} from "../../redux/stories/thunk";
import { format_date, format_datetime, isArray } from "../../utils/formatersAndParsers";
import useAuth from "../../hooks/useAuth";
import { ErrorField } from "../../Component/common/errorField";
import { defaultPage, maleAvatarHolder, reactionsIcons } from "../../utils/constants";
import BookmarkSuccess from "../../Component/Modals/bookmarkSuccess";
import { getSlug, getUserImage, handleLoginPop } from "../../utils/helpers";
import ReportStoryPop from "../../Component/Modals/reportStoryPop";
import AddReaction from "../../Component/common/addReaction";
import { errorMsg } from "../../utils/toastMessage";
import MyPagination from "../../Component/common/pagination";
import { manageBookmark } from "../../redux/bookmarks/thunk";
import StoryDetailsSkeleton from "../../Component/skeleton/storyDetailsSkeleton";
import { SubFooter } from "../../Component/Footer/Footer";
import { chatRoute, otherUserProfile, storyDetailsRoute } from "../../routes/routesPath";
import MyHelmet from "../../seo/helmet";

export default function StoryDetails() {
  const { isAuthenticated } = useAuth();
  const { storyId } = useParams();
  const navigate = useNavigate();
  const { userProfile } = useSelector((state) => state.auth);

  const [userAction, setUserAction] = useState(null);
  const [paginationDetails, setPaginationDetails] = useState(defaultPage);
  const [inputValue, setInputValue] = useState({});
  const [errors, setErrors] = useState({});
  const [addReply, setAddReply] = useState(null);
  const [commentsList, setCommentsList] = useState({});
  const [storyDetails, setStoryDetails] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputValue((prev) => ({ ...prev, [name]: value }));
  };

  const handleStoryDetails = async () => {
    const response = await handleApiRequest(getStoryDetails, { storyId });
    if (response.status) {
      setStoryDetails(response.data);
    }
  };

  const handleCommentList = async () => {
    const request = { storyId, ...paginationDetails };
    const response = await handleApiRequest(getCommentList, request);

    if (response.status) {
      setCommentsList(response.data || {});
    }
  };

  const handleAddComment = async () => {
    if (!isAuthenticated) return handleLoginPop(true);
    if (inputValue.comment?.length < 15)
      return setErrors((prev) => ({ ...prev, comment: "Must be atleast 15 charater long" }));
    else {
      setErrors({});
    }

    setUserAction({ action: "showCommentLoader" });
    const request = { storyId, comment: inputValue.comment };
    const response = await handleApiRequest(addComment, request);
    setUserAction(null);

    if (response.status) {
      setStoryDetails((prev) => ({ ...prev, commentsCount: prev.commentsCount + 1 }));
      setInputValue({});

      const newComment = {
        user: userProfile,
        comment: inputValue.comment,
        createdAt: new Date(),
        _id: new Date(),
      };
      setCommentsList((prev) => ({
        ...prev,
        records: [newComment, ...(prev.records || [])],
        totalCount: prev.totalCount + 1,
      }));
    }
  };

  const handleBookmark = async () => {
    if (!isAuthenticated) return handleLoginPop(true);
    setUserAction({ action: "bookmarkProgress" });
    setStoryDetails((prev) => ({ ...prev, isBookmarked: !prev.isBookmarked }));

    const response = await handleApiRequest(manageBookmark, { storyId });
    if (response.status) {
      setUserAction({ action: "bookmarkSuccess", isBookmarked: !storyDetails.isBookmarked });
    } else {
      setStoryDetails((prev) => ({ ...prev, isBookmarked: !prev.isBookmarked }));
      setUserAction(null);
    }
  };

  const handleCommentReply = async () => {
    if (inputValue.reply?.length < 10)
      return setErrors((prev) => ({ ...prev, reply: "Must be atleast 10 charater long" }));
    else {
      setErrors({});
    }
    setUserAction({ action: "showReplyLoader" });

    const request = { storyId, commentId: addReply, reply: inputValue.reply };
    const response = await handleApiRequest(addCommentReply, request);
    setUserAction(null);

    if (response.status) {
      setCommentsList((prev) => {
        const oldComments = prev.records || [];
        const index = oldComments.findIndex((item) => item._id === addReply);
        if (index >= 0) {
          const oldComment = prev.records?.[index];
          const newReply = {
            _id: new Date(),
            comment: oldComment._id,
            reply: inputValue.reply,
            createdAt: new Date(),
            comment: oldComment.story,
            user: {
              _id: userProfile._id,
              name: userProfile.name,
              gender: userProfile.gender,
              avatar: userProfile.avatar,
            },
          };

          oldComments.splice(index, 1, {
            ...oldComment,
            repliesCount: oldComment.repliesCount + 1,
            replies: [newReply, ...(oldComment.replies || [])],
          });
        }

        return { records: oldComments, totalCount: prev.totalCount };
      });

      setInputValue({});
      setAddReply("");
    }
  };

  const handleRepliesList = async (commentId) => {
    const index = commentsList.records?.findIndex((item) => item._id === commentId);
    if (index < 0) return errorMsg("Something wrong happend");
    setUserAction({ action: "loadingReplies" });

    const repliesCount = isArray(commentsList.records?.[index]?.replies).length;
    let [page, limit] = [1, 10];

    for (let j = 1; j <= repliesCount; j++) {
      const possibleLimit = repliesCount / (j - 1);
      if (Number.isInteger(possibleLimit) && possibleLimit <= 20) {
        [page, limit] = [j, possibleLimit];
        break;
      }
    }

    const request = { commentId, orderBy: "createdAt", order: -1, page, limit };
    const response = await handleApiRequest(getRepliesList, request);
    setUserAction(null);

    if (response.status) {
      setCommentsList((prev) => {
        const oldComments = prev.records;
        const oldComment = oldComments[index];
        (oldComment.replies || []).push(...isArray(response.data?.records));

        oldComments.splice(index, 1, oldComment);
        return { records: oldComments, totalCount: prev.totalCount };
      });
    }
  };

  const handleUpdateCommentList = (commentId) => {
    const index = commentsList.records?.findIndex((item) => item._id === commentId);

    setCommentsList((prev) => {
      const oldComments = prev.records;
      const oldComment = oldComments[index];
      oldComments.splice(index, 1, {
        ...oldComment,
        likesCount: oldComment.myReaction
          ? oldComment.likesCount - 1
          : (oldComment.likesCount || 0) + 1,
        myReaction: oldComment.myReaction ? "" : "like",
      });
      return { records: oldComments, totalCount: prev.totalCount };
    });
  };

  const handleCommentReaction = async (commentId) => {
    if (!isAuthenticated) return handleLoginPop(true);

    handleUpdateCommentList(commentId);

    const request = {
      storyId: storyDetails._id,
      commentId,
      reaction: "like",
    };

    const response = await handleApiRequest(manageCommentReaction, request);
    if (!response.status) {
      handleUpdateCommentList(commentId);
    }
  };

  useEffect(() => {
    handleStoryDetails();
    handleCommentList();
  }, [storyId]);

  return (
    <>
      {storyDetails.title && (
        <MyHelmet
          title={getSlug(storyDetails.title)}
          keywords={[]}
          description={htmlToText(storyDetails.description)?.slice(0, 150)}
          link={`${storyDetailsRoute}/${getSlug(storyDetails.title)}/${storyDetails._id}`}
        />
      )}

      <section className="storyDetailsContainer">
        <div className="py-4 px-3 px-lg-5">
          {storyDetails._id ? (
            <>
              <div className="d-flex align-items-center justify-content-between">
                <Link
                  to={
                    !storyDetails.anonymousSharing
                      ? `${otherUserProfile}/${getSlug(storyDetails.user?.name)}/${
                          storyDetails.user?._id
                        }`
                      : ""
                  }
                  className="mainStoryCardHeader text-color-unset text-decoration-none pointer d-flex align-items-center"
                >
                  <img
                    className="img-medium img-fluid rounded-circle"
                    alt={storyDetails.anonymousSharing ? "userImage" : storyDetails.user?.name}
                    src={
                      storyDetails.anonymousSharing
                        ? getUserImage()
                        : getUserImage(storyDetails.user)
                    }
                    onError={() => {
                      setStoryDetails((prev) => {
                        const updatedUser = { ...prev.user, avatar: { url: maleAvatarHolder } };
                        return {
                          ...prev,
                          user: updatedUser,
                        };
                      });
                    }}
                  />
                  <div className="ms-2">
                    <p className="my-0">
                      {storyDetails.anonymousSharing ? "Anonymous" : storyDetails.user?.name}
                    </p>
                    <p className="muted small my-0">
                      Posted At: {format_date(storyDetails.createdAt)}
                    </p>
                  </div>
                </Link>

                {storyDetails.user !== userProfile._id && (
                  <Dropdown>
                    <Dropdown.Toggle className="bg-primary border-0">
                      <p className="m-0 d-flex py-1">
                        <HiDotsVertical />
                      </p>
                    </Dropdown.Toggle>

                    <Dropdown.Menu align="end">
                      <Dropdown.Item
                        as="p"
                        className="pointer m-0 px-2 py-1 d-flex align-items-center"
                        onClick={handleBookmark}
                      >
                        {storyDetails?.isBookmarked ? (
                          <>
                            <FaBookmark className="me-1" />
                            Unsave
                          </>
                        ) : (
                          <>
                            <FaRegBookmark className="me-1" />
                            Save
                          </>
                        )}
                      </Dropdown.Item>
                      <Dropdown.Item
                        as="p"
                        className="pointer m-0 px-2 py-1 d-flex align-items-center"
                        onClick={() => {
                          if (!isAuthenticated) return handleLoginPop(true);
                          setUserAction({ action: "reportStory", id: storyId });
                        }}
                      >
                        <FaFlag className="me-1" />
                        Report Story
                      </Dropdown.Item>
                      {!storyDetails.anonymousSharing && (
                        <>
                          <Dropdown.Item
                            as="p"
                            className="pointer m-0 px-2 py-1 d-flex align-items-center"
                            onClick={() => {
                              navigate(
                                `${otherUserProfile}/${getSlug(storyDetails.user?.name)}/${
                                  storyDetails.user?._id
                                }`
                              );
                            }}
                          >
                            <FaCircleUser className="me-1" />
                            Visit Profile
                          </Dropdown.Item>
                          <Dropdown.Item
                            as="p"
                            className="pointer m-0 px-2 py-1 d-flex align-items-center"
                            onClick={() => {
                              if (!isAuthenticated) return handleLoginPop(true);
                              navigate(`${chatRoute}?receiver=${storyDetails.user._id}`);
                            }}
                          >
                            <FaCommentDots className="me-1" />
                            Message User
                          </Dropdown.Item>
                        </>
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                )}
              </div>

              <h1 className="h4 my-3">{storyDetails.title}</h1>
              <div className="storyDesc-dark storyDetailDesc">
                <p dangerouslySetInnerHTML={{ __html: storyDetails.description }} />
              </div>
            </>
          ) : (
            <StoryDetailsSkeleton className="py-0 px-0 px-lg-0" />
          )}

          <div className="storyAction d-flex align-items-center justify-content-start mb-3">
            <p>
              <span dangerouslySetInnerHTML={{ __html: storyDetails.category?.iconFilled }} />
              {storyDetails.category?.name}
            </p>
            <p>
              <span>
                <FaRegCommentDots style={{ width: 22 }} />
              </span>
              Comments ({storyDetails.commentsCount || 0})
            </p>
            <div className="position-relative">
              <AddReaction
                setStoryDetails={setStoryDetails}
                savedReaction={storyDetails.myReaction}
                reactionsCount={storyDetails?.reactionsCount}
                storyId={storyDetails._id}
                position="left"
              />
            </div>
          </div>
          <div>
            <textarea
              className="myInput form-control mt-4"
              placeholder="Share your thoughts"
              name="comment"
              value={inputValue.comment || ""}
              onChange={handleChange}
            />
          </div>
          <div className="d-flex justify-content-between">
            <div>
              <ErrorField error={errors.comment} />
            </div>
            <button
              className="primaryBtn rounded mt-2 px-3 py-1"
              disabled={!inputValue.comment || userAction?.action === "showCommentLoader"}
              onClick={handleAddComment}
            >
              {userAction?.action === "showCommentLoader" ? (
                <>
                  Saving your comment
                  <i className="growLoader spinner-grow ms-2" role="status" />
                </>
              ) : (
                "Add Comment"
              )}
            </button>
          </div>
        </div>

        <div className="curvatureContainer border-0">
          <div className="bg-white">
            <div id="curved-corner-topright" className="bg-white" />
          </div>
          <div className="bg-white">
            <div id="curved-corner-topleft" className="bg-white" />
          </div>
        </div>

        <div className="pb-3 px-3 px-lg-5">
          <h4 className="mb-3">Comments</h4>

          {commentsList.totalCount ? (
            commentsList.records?.map((comment, i) => {
              return (
                <div key={comment?._id} className="shadow px-3 px-lg-4 py-3 mb-2">
                  <div className="d-flex align-items-center">
                    <img
                      src={getUserImage(comment.user)}
                      alt={comment.user?.name}
                      onError={(e) => {
                        e.target.src = getUserImage();
                      }}
                      className="img-medium img-fluid"
                    />
                    <div className="ms-2">
                      <p className="my-0">{comment.user?.name}</p>
                      <p className="muted small my-0">
                        Comment At: {format_datetime(comment.createdAt)}
                      </p>
                    </div>
                  </div>

                  <div className="mt-2">
                    {comment.comment}
                    <div className="storyAction d-flex align-items-center justify-content-start my-3">
                      <p onClick={() => setAddReply(comment._id)}>
                        <span>
                          <RiReplyFill />
                        </span>
                        Reply ({comment.repliesCount || 0})
                      </p>
                      <p onClick={() => handleCommentReaction(comment._id)}>
                        <span>
                          {comment.myReaction ? reactionsIcons[comment.myReaction] : <BiLike />}
                        </span>
                        {comment.likesCount || 0}
                      </p>
                    </div>

                    {addReply === comment._id && (
                      <>
                        <div>
                          <textarea
                            className="myInput form-control mt-4"
                            placeholder="Add your reply"
                            name="reply"
                            value={inputValue.reply}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="d-flex justify-content-between mb-2">
                          <div>
                            <ErrorField error={errors.reply} />
                          </div>
                          <button
                            className="primaryBtn rounded px-3 mt-2"
                            disabled={!inputValue.reply || userAction?.action === "showReplyLoader"}
                            onClick={handleCommentReply}
                          >
                            {userAction?.action === "showReplyLoader" ? (
                              <>
                                Saving
                                <i className="growLoader spinner-grow ms-2" role="status" />
                              </>
                            ) : (
                              "Reply"
                            )}
                          </button>
                        </div>
                      </>
                    )}

                    {comment.replies?.map((reply, i) => {
                      return (
                        <div key={reply._id} className="shadow-sm mx-2 px-2 pt-2 mb-2">
                          <div className="d-flex">
                            <img
                              src={getUserImage(reply.user)}
                              alt={reply.user?.name}
                              onError={(e) => {
                                e.target.src = getUserImage();
                              }}
                              className="img-small img-fluid"
                            />
                            <div className="ms-2">
                              <p className="my-0">{reply.user?.name}</p>
                              <p className="text-small muted my-0">
                                Replied At: {format_datetime(reply.createdAt)}
                              </p>
                            </div>
                          </div>
                          <p className="muted py-3" style={{ lineHeight: "20px" }}>
                            Reply: {reply.reply}
                          </p>
                        </div>
                      );
                    })}

                    {comment.replies?.length > 0 &&
                      comment.replies?.length < comment.repliesCount && (
                        <div className="text-end">
                          <button
                            className="textBtn mx-2 mt-0"
                            disabled={userAction?.action === "loadingReplies"}
                            onClick={() => handleRepliesList(comment._id)}
                          >
                            {userAction?.action === "loadingReplies" ? "Loading..." : "Load More"}
                          </button>
                        </div>
                      )}
                  </div>
                </div>
              );
            })
          ) : (
            <>
              <img
                src={beTheFirstToAddComment}
                alt="addFirstComment"
                className="img-fluid d-flex rounded-5 mx-auto"
                style={{ maxWidth: 400 }}
              />
            </>
          )}
          <div className="d-flex justify-content-end">
            <MyPagination
              paginationDetails={paginationDetails}
              setPaginationDetails={setPaginationDetails}
              totalCount={commentsList.totalCount}
              darkPagination={true}
            />
          </div>
        </div>

        {/* 
        <div className="curvatureContainer lowerCurvatureContainer">
          <div>
            <div id="curved-corner-bottomright" className="curvedCorner" />
          </div>
          <div>
            <div id="curved-corner-bottomleft" className="curvedCorner" />
          </div>
        </div> */}
        <SubFooter />
      </section>

      {(userAction?.action === "bookmarkSuccess" || userAction?.action === "bookmarkProgress") && (
        <BookmarkSuccess userAction={userAction} setUserAction={setUserAction} />
      )}
      {userAction?.action === "reportStory" && (
        <ReportStoryPop userAction={userAction} setUserAction={setUserAction} />
      )}
    </>
  );
}
