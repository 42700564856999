import React, { useEffect, useState } from "react";
import { Col, Dropdown, Row } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { debounce } from "lodash";
import InfiniteScroll from "react-infinite-scroll-component";
import { ClipLoader } from "react-spinners";
import { RiSortAsc } from "react-icons/ri";
import { FaCheck } from "react-icons/fa6";
import TransparentSelect from "../../Component/common/transparentSelect";
import { defaultPage, sortingOptions } from "../../utils/constants";
import { isArray } from "../../utils/formatersAndParsers";
import { handleApiRequest } from "../../services/handleApiRequest";
import { handleTransformValues } from "../../utils/formatersAndParsers";
import { getMainStoriesList } from "../../redux/stories/thunk";
import LoadIndicator from "../../Component/Loader";
import StoryCard from "../../Component/storyCard/mainStoryCard";
import CardSkeleton from "../../Component/skeleton/cardSkeleton";
import { SubFooter } from "../../Component/Footer/Footer";
import MyHelmet from "../../seo/helmet";
import { storiesRoute } from "../../routes/routesPath";

export default function Stories() {
  const [searchParams, setSearchParams] = useSearchParams();
  const category = searchParams.get("category");
  const { categories = [] } = useSelector((state) => state.common || {});
  const { storiesList = {}, loadingStoriesList = false } = useSelector(
    (state) => state.stories || {}
  );

  const [paginationDetails, setPaginationDetails] = useState({
    ...defaultPage,
    orderBy: sortingOptions[0]?.orderBy,
  });
  const [filters, setFilters] = useState({});
  const [showReaction, setShowReaction] = useState("");

  const handleStories = async () => {
    const selectedFilters = await handleTransformValues(filters);

    const request = {
      ...paginationDetails,
      ...selectedFilters,
    };
    await handleApiRequest(getMainStoriesList, request);
  };

  const handleSearch = debounce(async (e) => {
    const { value, name } = e.target;
    setFilters((prev) => ({ ...prev, [name]: value }));
  }, 1000);

  const feelingFilterOption = (option) => {
    return (
      <div className="d-flex align-items-center">
        <span
          className="optionIcon me-2"
          dangerouslySetInnerHTML={{ __html: option.iconRegular }}
        />
        <span className="">{option.name}</span>
      </div>
    );
  };

  useEffect(() => {
    if (!category || (category && filters.category)) {
      handleStories();
    }
  }, [paginationDetails, filters]);

  useEffect(() => {
    if (categories?.length > 0) {
      const selectedCategory = categories.find((cat) => cat._id === category);
      setFilters((prev) => ({ ...prev, category: selectedCategory || "" }));
      setPaginationDetails((prev) => ({ ...prev, page: 1 }));
    }
  }, [searchParams, categories]);

  return (
    <>
      <MyHelmet
        title={"Read what others are feeling"}
        keywords={["Read life stories"]}
        description="FeelingHub is a platform to share feelings online, read confessions, life stories and problems of others. Share your thoughts on their experiences and connect!"
        link={storiesRoute}
      />

      <section className="storiesListContainer">
        <Row className="m-0">
          <Col lg={8} className="p-0">
            <div className="bg-header me-lg-5">
              {isArray(storiesList.records).length > 0 && (
                <div
                  className={`${
                    loadingStoriesList ? "" : "hide-loader"
                  } d-flex align-items-center justify-content-center`}
                >
                  <LoadIndicator />
                </div>
              )}

              <div className="storyFilters d-flex align-items-center justify-content-between p-3 px-2 ps-lg-5 mb-3">
                <div>
                  <input
                    type="text"
                    className="bg-transparent border-0 rounded-0 border-bottom border-bottom-strong border-primary form-control shadow-none"
                    id="search"
                    placeholder="Search"
                    name="search"
                    onChange={handleSearch}
                  />
                </div>
                <div className="d-flex align-items-center justify-content-between">
                  <TransparentSelect
                    placeholder="Select Feeling"
                    className="storyFilterContainer"
                    options={[{ name: "All", _id: "" }, ...isArray(categories)]}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option._id}
                    formatOptionLabel={feelingFilterOption}
                    value={filters.category}
                    onChange={(selected) => {
                      setSearchParams((prev) => `category=${selected._id}`, { replace: true });
                    }}
                  />
                  <Dropdown>
                    <Dropdown.Toggle
                      variant="transparent"
                      id="dropdown-basic"
                      className="border-0 hstack gap-1"
                    >
                      Sort
                      <RiSortAsc />
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      {sortingOptions.map((option) => (
                        <Dropdown.Item
                          key={option.value}
                          as={"p"}
                          className="pointer m-0"
                          onClick={(e) => {
                            e.preventDefault();
                            setPaginationDetails((prev) => ({
                              ...prev,
                              page: 1,
                              orderBy: option.orderBy,
                              order: option.order,
                            }));
                          }}
                        >
                          {paginationDetails.orderBy === option.orderBy &&
                          paginationDetails.order === option.order ? (
                            <span className="d-flex align-items-center text-primary gap-10 fw-bold">
                              {option.label}
                              <FaCheck />
                            </span>
                          ) : (
                            option.label
                          )}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            </div>

            <div className={`px-2 px-lg-5`}>
              {storiesList.records ? (
                <InfiniteScroll
                  dataLength={storiesList.records?.length}
                  next={() => setPaginationDetails((prev) => ({ ...prev, page: prev.page + 1 }))}
                  hasMore={storiesList.records?.length < storiesList.totalCount}
                  loader={
                    <div className="text-center my-4">
                      <ClipLoader
                        color={"var(--primary-color)"}
                        cssOverride={{ borderWidth: 5 }}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                      />
                    </div>
                  }
                  scrollableTarget="scrollableDiv"
                  endMessage={
                    <p style={{ textAlign: "center" }}>
                      <b>Yay! You have seen it all</b>
                    </p>
                  }
                >
                  {isArray(storiesList.records).map((story, i) => (
                    <StoryCard
                      key={story._id}
                      story={story}
                      showReaction={showReaction}
                      setShowReaction={setShowReaction}
                    />
                  ))}
                </InfiniteScroll>
              ) : (
                Array.from({ length: 3 }).map((_, index) => <CardSkeleton key={index} />)
              )}
            </div>
          </Col>
          <Col className="d-none d-lg-block col-lg-4 py-2">
            <div className="shadow h-100 d-flex align-items-center justify-content-center my-3 rounded">
              Google Ad
            </div>
          </Col>
        </Row>

        <SubFooter />

        {/* <div className="curvatureContainer lowerCurvatureContainer">
          <div>
            <div id="curved-corner-bottomright" className="curvedCorner" />
          </div>
          <div>
            <div id="curved-corner-bottomleft" className="curvedCorner" />
          </div>
        </div> */}
      </section>
    </>
  );
}
