import React, { useEffect } from "react";
import { Row, Col, Form } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import { loginSchema } from "../../../utils/validationSchemas";
import { successMsg } from "../../../utils/toastMessage";
import { getMyAccountPagePath, handleLoginPop } from "../../../utils/helpers";
import { resetPasswordRoute, signupRoute } from "../../../routes/routesPath";
import { handleApiRequest } from "../../../services/handleApiRequest";
import { login } from "../../../redux/auth/thunk";
import SideSection from "../components/sideSection";
import MyForm from "../../../Component/formComponent";
import SocialGoogleLogin from "../socialLogin/googleLogin";
import AppLogo from "../../../Component/appLogo";
import MyHelmet from "../../../seo/helmet";

const formFields = [
  { value: "email", type: "email", placeholder: "Enter your email" },
  { value: "password", type: "password" },
  { value: "remember", type: "checkbox", label: "Remember Me" },
];

const Signin = ({ loginFromPop }) => {
  const navigate = useNavigate();
  const { rememberedUser } = useSelector((state) => state.auth);

  const handleLogin = async () => {
    const response = await handleApiRequest(login, { ...values, loginType: "normal" });

    if (response.status) {
      successMsg("Login Success!!");
      if (loginFromPop) {
        handleLoginPop(false);
      } else {
        navigate(getMyAccountPagePath());
      }
    }
  };

  const {
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    setFieldValue,
    setValues,
    isSubmitting,
    setSubmitting,
  } = useFormik({
    initialValues: { email: "", password: "", remember: false },
    validationSchema: loginSchema,
    onSubmit: handleLogin,
  });

  useEffect(() => {
    if (rememberedUser?.email) {
      setValues(rememberedUser);
    }
  }, [rememberedUser]);

  return (
    <>
      <MyHelmet
        title={"Join to connect with emotional support community"}
        keywords={[]}
        description="Feelinghub is an anonymous social platform to share feelings online or post life stories anonymously for free. It is a safe space to express yourself."
        link="/auth/sign-in"
      />

      <section className="position-relative">
        <div className="">
          <Row className={`authContainer m-0`} style={{ height: loginFromPop ? "auto" : "" }}>
            {!loginFromPop && <SideSection />}
            <Col
              lg={!loginFromPop ? 6 : 12}
              className={`authFormContainer px-3 ${loginFromPop ? "pb-3" : "py-5"}`}
            >
              <div className="w-100">
                <AppLogo />
                <div className="position-relative mt-3 w-100">
                  <Form className="" onSubmit={handleSubmit}>
                    <MyForm
                      values={values}
                      setFieldValue={setFieldValue}
                      errors={errors}
                      touched={touched}
                      handleChange={handleChange}
                      formFields={formFields}
                    />

                    {isSubmitting ? (
                      <button
                        className="secondaryBtn muted rounded w-100 d-flex align-items-center justify-content-center"
                        disabled
                      >
                        Getting your Details
                        <div className="growLoader spinner-grow ms-2" role="status" />
                      </button>
                    ) : (
                      <button type="submit" className="primaryBtn rounded w-100">
                        Login
                      </button>
                    )}
                  </Form>

                  <div className="medium my-4">
                    <p className="mb-0">
                      Forgot Password?
                      <span
                        onClick={() => {
                          handleLoginPop(false);
                        }}
                      >
                        <Link to={resetPasswordRoute} className="text-decoration-none">
                          {" "}
                          Reset here
                        </Link>
                      </span>
                    </p>
                    <p className="mb-0">
                      Don't have an account?
                      <span
                        onClick={() => {
                          handleLoginPop(false);
                        }}
                      >
                        <Link to={signupRoute} className="text-decoration-none">
                          {" "}
                          Create
                        </Link>
                      </span>
                    </p>
                  </div>
                  <SocialGoogleLogin isSubmitting={isSubmitting} setSubmitting={setSubmitting} />
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </section>
    </>
  );
};

export default Signin;
