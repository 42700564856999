import { HiHandThumbUp } from "react-icons/hi2";
import { FaHandHoldingHeart, FaHeart } from "react-icons/fa6";
import { TbBulbFilled } from "react-icons/tb";
import { FaLaughBeam } from "react-icons/fa";

export const skeletonBaseColor = "#d3d3d3";
export const skeletonHighlightColor = "#e0e0e0";

export const passwordregex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

export const maleAvatarHolder = "/images/userImage.png";
export const femaleAvatarHolder = "/images/userImage-girl.png";

export const genderOptions = [
  { value: "male", label: "Male" },
  { value: "female", label: "Female" },
  { value: "other", label: "Other" },
];

export const userReportReasons = [
  { value: "nudityOrSexualActivity", label: "Nudity or sexual activity" },
  { value: "hateSpeech", label: "Hate speech" },
  { value: "scamOrFraud", label: "Scam or fraud" },
  { value: "bullyingOrHarassment", label: "Bullying or harassment" },
  { value: "saleOfIllegalProducts", label: "Sale of illegal products" },
  { value: "violenceOrDanger", label: "Violence or danger" },
  { value: "spam", label: "Spam" },
];

export const storyReportReasons = [
  { value: "abusive", label: "Abusive" },
  { value: "askForMoney", label: "Ask for money" },
  { value: "violence", label: "Violence" },
  { value: "hateSpeech", label: "Hate speech" },
  { value: "falseInformation", label: "False information" },
];

export const sortingOptions = [
  { value: "relevance", label: "Relevance", orderBy: "_id", order: -1 },
  { value: "latestFirst", label: "Latest first", orderBy: "createdAt", order: -1 },
  { value: "oldestFirst", label: "Oldest first", orderBy: "createdAt", order: 1 },
];

export const defaultPage = {
  orderBy: "_id",
  order: -1,
  page: 1,
  limit: 20,
};

export const limitOptions = [
  { value: 20, label: 20 },
  { value: 50, label: 50 },
  { value: 75, label: 75 },
  { value: 100, label: 100 },
];

export const reactionsIcons = {
  like: <HiHandThumbUp style={{ color: "#2684ff" }} />,
  love: <FaHeart style={{ color: "red" }} />,
  laugh: <FaLaughBeam style={{ color: "orange" }} />,
  information: <TbBulbFilled style={{ color: "#ffe000", width: 30, height: 30 }} />,
  support: <FaHandHoldingHeart style={{ color: "#c90000" }} />,
};

export const contactUsReason = [
  { value: "suggestionOrFeedback", label: "Suggestion or Feedback" },
  { value: "problemOrComplaint", label: "Problem or Complaint" },
  {
    value: "reportIllegalOrInappropriateContent",
    label: "Report illegal or Inappropriate content",
  },
];

export const socialHandles = {
  instagram: "https://www.instagram.com/feelinghub_official?igsh=MWQ3cmY3eHd2c3UxcA==",
  facebook: "https://www.facebook.com/profile.php?id=61570553554652&mibextid=ZbWKwL",
  youtube: "https://youtube.com/@feelinghub_official?si=CTYX1S12sYnAC-6u",
  telegram: "#",
  whatsapp: "https://whatsapp.com/channel/0029Vb0XaPj7z4kkSZ2Rqi2I",
};

// SEO data
export const defaultDescription =
  "Feelinghub is a safe space to share your emotions, confessions, and life stories anonymously. Comment, react, and connect through end-to-end encrypted chats.";
export const mainKeywords = [
  "share feelings online", // Primary keyword
  "Confession sharing app",
  "Share emotions safely and securely",

  "Anonymous feelings sharing platform",
  "Emotional support community",
  "Post life stories anonymously",
  "Anonymous social platform",
];

// "Chat anonymously online",

// "Read and react to life stories",
// "Share love, anger, confessions",
// "Online diary for feelings",
// "Comment and react to stories",
// "Connect through emotions",
// "Share feelings anonymously without judgment",
