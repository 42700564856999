import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { htmlToText } from "html-to-text";
import { handleApiRequest } from "../../services/handleApiRequest";
import { SubFooter } from "../../Component/Footer/Footer";
import { getContentPage } from "../../redux/common/thunk";
import ContentPageSkeleton from "../../Component/skeleton/contentPageSkeleton";
import MyHelmet from "../../seo/helmet";
import { cmsPage } from "../../routes/routesPath";
import { getSlug } from "../../utils/helpers";

export default function ContentPage() {
  const { pageId } = useParams();
  const [pageData, setPageData] = useState({});

  const handlePageDetails = async () => {
    const response = await handleApiRequest(getContentPage, { pageId });
    if (response.status) {
      setPageData(response.data);
    }
  };

  useEffect(() => {
    if (pageId) {
      handlePageDetails();
    }
  }, [pageId]);

  return (
    <>
      {pageData?.title && (
        <MyHelmet
          title={pageData?.title}
          keywords={[]}
          description={htmlToText(pageData.content)?.slice(0, 150)}
          link={`${cmsPage}/${getSlug(pageData.title)}/${pageData._id}`}
        />
      )}

      <section className="home">
        {pageData.title ? (
          <div className="mx-2 mx-lg-5 my-4">
            <div className="w-fit border-bottom border-danger mb-4">
              <h1 className="bg-primary border-0 text-white rounded px-3 py-1 mb-3 mt-5 h6 py-2">
                {pageData.title}
              </h1>
              <p className="border-top border-danger w-50 mb-1" />
            </div>

            <div dangerouslySetInnerHTML={{ __html: pageData.content }} />
          </div>
        ) : (
          <ContentPageSkeleton />
        )}
        <SubFooter />
      </section>
    </>
  );
}
