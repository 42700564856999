import React from "react";
import { useNavigate } from "react-router-dom";
import { useGoogleLogin } from "@react-oauth/google";
import { ReactComponent as GoogleIcon } from "../../../assets/icons/googleIcon.svg";
import { handleApiRequest } from "../../../services/handleApiRequest";
import { login } from "../../../redux/auth/thunk";
import { signupRoute } from "../../../routes/routesPath";
import { getMyAccountPagePath } from "../../../utils/helpers";

const SocialGoogleLogin = ({ isSubmitting, setSubmitting }) => {
  const navigate = useNavigate();

  const handleLogin = useGoogleLogin({
    onSuccess: async (codeResponse) => {
      const request = { token: `Bearer ${codeResponse.access_token}`, loginType: "google" };
      const response = await handleApiRequest(login, request);

      setSubmitting(false);
      if (response.statusCode === 206) {
        navigate(signupRoute);
      } else if (response.status) {
        navigate(getMyAccountPagePath());
      }
    },
    onError: (error) => {
      setSubmitting(false);
      // console.log("Login Failed:", error);
    },
    onNonOAuthError: (error) => {
      setSubmitting(false);
      // console.log("Login Failed onNonOAuthError:", error);
    },
  });

  return (
    <>
      <div className={`googleLoginBtn`}>
        <button
          className={`primaryBtn w-100 rounded py-2 px-3`}
          disabled={isSubmitting}
          onClick={() => {
            if (!isSubmitting) {
              setSubmitting(true);
              handleLogin();
            }
          }}
        >
          <span className="icn">
            <GoogleIcon />
          </span>
          <span className="ms-2">Continue with google</span>
          {isSubmitting && <i className="growLoader spinner-grow ms-2" role="status" />}
        </button>
      </div>
    </>
  );
};

export default SocialGoogleLogin;
