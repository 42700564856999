import { useEffect, useState } from "react";
import requestToken from "../firebase/requestToken";
import store from "../redux/store";
import { errorMsg } from "../utils/toastMessage";
import { handleApiRequest } from "../services/handleApiRequest";
import { manageFirebaseToken } from "../redux/common/thunk";

export default function useNotificationPermissions() {
  const { loggedinUser } = store.getState().auth;
  const [notificationPermission, setNotificationPermission] = useState();

  const handleFirebaseToken = async (token) => {
    const request = { firebaseToken: token };
    const response = await handleApiRequest(manageFirebaseToken, request);
    if (!response.status) {
      errorMsg("Failed to enable notification");
    }
  };

  useEffect(() => {
    if ("permissions" in navigator) {
      navigator.permissions.query({ name: "notifications" }).then(function (notificationPerm) {
        setNotificationPermission(notificationPerm.state);
        notificationPerm.onchange = function () {
          setNotificationPermission(notificationPerm.state);
        };
      });
    }
  }, []);

  useEffect(() => {
    (async () => {
      try {
        if (loggedinUser.token) {
          if (notificationPermission === "prompt") {
            await Notification.requestPermission();
          }
          if (
            notificationPermission &&
            notificationPermission === "granted" &&
            !loggedinUser.firebaseToken
          ) {
            const firebaseToken = await requestToken();
            if (firebaseToken) {
              handleFirebaseToken(firebaseToken);
            }
          } else if (notificationPermission === "denied") {
            errorMsg("Notification permission denied");
          }
        }
      } catch (error) {
        console.log("error", error);
      }
    })();
  }, [notificationPermission, loggedinUser]);

  return {};
}
