import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { IoIosArrowForward } from "react-icons/io";
import * as Path from "../../routes/routesPath";
import { getMyAccountPagePath, getSlug, getUserImage } from "../../utils/helpers";
import { handleApiRequest } from "../../services/handleApiRequest";
import { logoutUser } from "../../redux/auth/thunk";
import DeletePopup from "../../Component/Modals/deletePop";
import { isArray } from "../../utils/formatersAndParsers";
import useAuth from "../../hooks/useAuth";
import SharePop from "../../Component/Modals/sharePop";

export const userCenter = [
  { label: "My Account", path: getMyAccountPagePath() },
  {
    label: "Saved Stories",
    path: getMyAccountPagePath(Path.bookmarkedStoriesRoute),
  },
  {
    label: "Private Directory",
    path: getMyAccountPagePath(Path.privateDirectoryRoute),
  },
  {
    label: "Feedback and Queries",
    path: getMyAccountPagePath(Path.myQueriesRoute),
  },
  // { value: "replies", label: "Comments and Replies", path: "" },
];

export default function MyAccountSideOptions({ wrapperClassName = "", handleClose = () => {} }) {
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();

  const { userProfile } = useSelector((state) => state.auth || {});
  const { contentPagesList } = useSelector((state) => state.common || {});
  const [userAction, setUserAction] = useState(null);

  const handleLogout = async () => {
    navigate(Path.homeRoute);
    await handleApiRequest(logoutUser, {}, false);
    setUserAction({});
  };

  return (
    <>
      <div className={`profileOptions ${wrapperClassName}`}>
        {isAuthenticated && (
          <div className="text-center px-2">
            <img
              className="img-large img-fluid object-fit-cover rounded-circle mt-3 mb-1"
              src={getUserImage(userProfile)}
              alt={!userProfile ? "userImage" : userProfile?.name}
              onError={(e) => {
                e.target.src = getUserImage();
              }}
            />
            <p className="truncate-1  m-0">{userProfile?.name}</p>
            <p className="truncate-1 muted small m-0" style={{ lineHeight: "12px" }}>
              {userProfile?.email}
            </p>
          </div>
        )}
        <div className="profileItemsList mt-3">
          {isAuthenticated && (
            <div className="mx-2 px-2">
              {userCenter.map((option, i) => (
                <Link
                  key={option.path}
                  to={option.path}
                  className={`profileItem mb-0 pt-2 pb-1 d-flex align-items-center justify-content-between border-bottom text-decoration-none text-color `}
                >
                  <p
                    className="align-items-center d-flex justify-content-between mb-0 w-100"
                    onClick={handleClose}
                  >
                    {option.label}
                    <IoIosArrowForward />
                  </p>
                </Link>
              ))}
              <p
                className={`profileItem mb-0 pt-2 pb-1 d-flex align-items-center justify-content-between border-bottom`}
                onClick={() => {
                  handleClose();
                  setTimeout(() => {
                    setUserAction({ action: "showSharePop" });
                  }, 0);
                }}
              >
                Invite Friends
                <IoIosArrowForward />
              </p>
            </div>
          )}
          <div className="mx-2 my-3">
            <div className="w-fit mb-2 ms-1 border-danger border-bottom">
              <b className="mb-1">FeelingHub</b>
              <p className="w-50 border-top border-danger mb-1" />
            </div>
            <div className="px-2">
              <Link
                to={Path.contactUsRoute}
                className={`profileItem mb-0 pt-2 pb-1 d-flex align-items-center justify-content-between border-bottom text-decoration-none text-color `}
              >
                <p
                  className="align-items-center d-flex justify-content-between mb-0 w-100"
                  onClick={handleClose}
                >
                  Contact Us
                  <IoIosArrowForward />
                </p>
              </Link>
              {isArray(contentPagesList).map((page) => (
                <Link
                  key={page._id}
                  to={`${Path.cmsPage}/${getSlug(page.title)}/${page._id}`}
                  className={`profileItem mb-0 pt-2 pb-1 d-flex align-items-center justify-content-between border-bottom text-decoration-none text-color `}
                >
                  <p
                    className="align-items-center d-flex justify-content-between mb-0 w-100"
                    onClick={handleClose}
                  >
                    {page.title}
                    <IoIosArrowForward />
                  </p>
                </Link>
              ))}
            </div>
          </div>
          {isAuthenticated && (
            <div className="px-2">
              <p
                className="mx-2 mb-3 mt-4 pb-1 d-flex align-items-center justify-content-between border-bottom border-danger"
                onClick={() => {
                  handleClose();
                  setTimeout(() => {
                    setUserAction({ action: "logout" });
                  }, 0);
                }}
              >
                <span>Logout</span>
                <span>
                  <IoIosArrowForward />
                </span>
              </p>
            </div>
          )}
        </div>
      </div>

      {userAction?.action === "showSharePop" && (
        <SharePop userAction={userAction} setUserAction={setUserAction} />
      )}

      {userAction?.action === "logout" && (
        <DeletePopup
          userAction={userAction}
          setUserAction={setUserAction}
          onSubmit={handleLogout}
          submitLabel={"Logout"}
        />
      )}
    </>
  );
}
