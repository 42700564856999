import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import noStoryPlaceholder from "../../assets/images/noStories.png";
import TransparentSelect from "../../Component/common/transparentSelect";
import { defaultPage, limitOptions, sortingOptions } from "../../utils/constants";
import { isArray } from "../../utils/formatersAndParsers";
import { handleApiRequest } from "../../services/handleApiRequest";
import { handleTransformValues } from "../../utils/formatersAndParsers";
import AskToShare from "../../Component/askToShare";
import ReactionsListsPop from "../../Component/Modals/reactionsListPop";
import { getBookmarksList } from "../../redux/bookmarks/thunk";
import LoadIndicator from "../../Component/Loader";
import StoryCard from "../../Component/storyCard/mainStoryCard";
import MySelect from "../../Component/common/mySelect";
import MyPagination from "../../Component/common/pagination";
import CardSkeleton from "../../Component/skeleton/cardSkeleton";
import { storiesRoute } from "../../routes/routesPath";
import MyHelmet from "../../seo/helmet";

export default function BookmarkedStories() {
  const navigate = useNavigate();
  const { categories } = useSelector((state) => state.common);
  const { loadingBookmarksList = false, bookmarksList = {} } = useSelector(
    (state) => state.bookmarks
  );

  const [userAction, setUserAction] = useState(null);
  const [paginationDetails, setPaginationDetails] = useState({
    ...defaultPage,
    orderBy: sortingOptions[0],
  });
  const [filters, setFilters] = useState({});
  const [showReaction, setShowReaction] = useState("");

  const handleFiltersChange = (name, value) => {
    setFilters((prev) => ({ ...prev, [name]: value }));
  };

  const handleLimit = (selected) => {
    setPaginationDetails((prev) => ({ ...prev, limit: selected }));
  };

  const handleStories = async () => {
    const selectedFilters = await handleTransformValues(filters);

    const request = {
      ...paginationDetails,
      orderBy: paginationDetails.orderBy?.orderBy,
      // ...selectedFilters,
    };
    await handleApiRequest(getBookmarksList, request);
  };

  const feelingFilterOption = (option) => {
    return (
      <div className="d-flex align-items-center">
        <span
          className="optionIcon me-2"
          dangerouslySetInnerHTML={{ __html: option.iconRegular }}
        />
        <span className="">{option.name}</span>
      </div>
    );
  };

  useEffect(() => {
    handleStories();
  }, [paginationDetails, filters]);

  return (
    <>
      <MyHelmet title={"Save the story that you like"} keywords="" description="" />

      <div className="w-100 overflow-auto">
        {bookmarksList.totalCount > 0 && (
          <div
            className={`${
              loadingBookmarksList ? "" : "hide-loader"
            } d-flex align-items-center justify-content-center`}
          >
            <LoadIndicator />
          </div>
        )}

        {bookmarksList?.totalCount === 0 ? (
          <div className="text-center my-4">
            <h3 className="m-0">Save stories those you like...</h3>
            <img src={noStoryPlaceholder} className="w-100" style={{ maxWidth: 400 }} />
            <h4 className="text-uppercase">Save your first story</h4>
            <button className="primaryBtn rounded px-3 py-2" onClick={() => navigate(storiesRoute)}>
              Save Stories to your bookmarks
            </button>
          </div>
        ) : (
          <>
            <div className="storyFilters d-block d-sm-flex align-items-center justify-content-between p-3 px-lg-5">
              <TransparentSelect
                placeholder="Relevance"
                className="sortingSelect"
                options={sortingOptions}
                value={paginationDetails.orderBy}
                onChange={(selected) =>
                  setPaginationDetails((prev) => ({
                    ...prev,
                    orderBy: selected,
                    order: selected.order,
                  }))
                }
              />
              <TransparentSelect
                placeholder="Filter by Feeling"
                className="storyFilterContainer"
                options={isArray(categories)}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option._id}
                formatOptionLabel={feelingFilterOption}
                value={filters.category}
                onChange={(selected) => handleFiltersChange("category", selected)}
              />
            </div>

            <div className={`px-3 px-lg-5`}>
              {isArray(bookmarksList.records).length > 0
                ? bookmarksList.records?.map((story, i) => (
                    <StoryCard
                      key={i}
                      story={story}
                      showReaction={showReaction}
                      setShowReaction={setShowReaction}
                    />
                  ))
                : Array.from({ length: 3 }).map((_) => <CardSkeleton />)}
              <div className="d-flex justify-content-between py-3">
                <div>
                  <MySelect
                    classNamePrefix={"mySelect"}
                    options={limitOptions}
                    isSearchable={false}
                    value={{ value: paginationDetails.limit, label: paginationDetails.limit }}
                    onChange={(selected) => handleLimit(selected)}
                  />
                </div>
                <MyPagination
                  paginationDetails={paginationDetails}
                  setPaginationDetails={setPaginationDetails}
                  totalCount={bookmarksList.totalCount}
                  darkPagination={true}
                />
              </div>
            </div>
          </>
        )}
        <div className="curvatureContainer">
          <div>
            <div id="curved-corner-topright" />
          </div>
          <div>
            <div id="curved-corner-topleft" />
          </div>
        </div>

        <AskToShare />
      </div>
      {userAction?.action === "showReactionsList" && (
        <ReactionsListsPop userAction={userAction} setUserAction={setUserAction} />
      )}
    </>
  );
}
