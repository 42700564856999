import React from "react";
import { FaFacebookF, FaInstagram, FaYoutube } from "react-icons/fa6";
import { FaTelegramPlane } from "react-icons/fa";
import { IoLogoWhatsapp } from "react-icons/io";
import { socialHandles } from "../../utils/constants";

export function InstagramHandle() {
  return (
    <a href={socialHandles.instagram} target="_blank" className="homeSocialIcon">
      <FaInstagram />
    </a>
  );
}

export function FacebookHandle() {
  return (
    <a href={socialHandles.facebook} target="_blank" className="homeSocialIcon">
      <FaFacebookF />
    </a>
  );
}

export function YoutubeHandle() {
  return (
    <a href={socialHandles.youtube} target="_blank" className="homeSocialIcon">
      <FaYoutube />
    </a>
  );
}

export function TelegramHandle() {
  return (
    <a href={socialHandles.telegram} target="_blank" className="homeSocialIcon">
      <FaTelegramPlane />
    </a>
  );
}

export function WhatsappHandle() {
  return (
    <a href={socialHandles.whatsapp} target="_blank" className="homeSocialIcon">
      <IoLogoWhatsapp />
    </a>
  );
}
